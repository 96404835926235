
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    //msg: String
  }
})
export default class Prefooter extends Vue {
  //msg!: string
}
