import { createStore } from 'vuex'
import MenuModule from './module/menu.js'

const store = createStore({
  modules:{
      menu: MenuModule,
  }
});


export default store;