
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'

export default defineComponent({
  name: 'MainMenu',
  components: {
    // 
  },
  data() {
      return {
        
      }
  },
  props: {
    msg: String
  },
  computed: {
    ...mapGetters("menu", {
      getMenu: "getMenu",
    }),
  },
  methods: {
    ...mapActions("menu", {
      updateMenu: "updateMenu",
    }),
  },
  mounted() {
    if (Object.keys(this.getMenu).length < 1) {
      this.updateMenu()
    }
  },
  setup () {
    //
  },
  watch: {
  }

})
