import axios from "axios"
import axiosRetry from 'axios-retry'
axiosRetry(axios, { retries: 3 })

const state = () => ({
    menu: {},
    sidebar: {},
})

const optionAxios = {
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 10000
}


const getters = {
    getMenu(state){
        return state.menu
    },
    getSidebar(state){
        return state.sidebar
    },
}

const actions = {
    async updateMenu({commit}) {
        axios.post("https://aurora-oil.ru/api/kz/menu.php", { }, optionAxios)
        .then(response => {
            if (response.data.status == 'success') {
                const data = response.data.data.json
                commit("saveMenu", data)
            }
        })
    },
    async updateSidebar({commit}) {
        axios.post("https://aurora-oil.ru/api/kz/sidebar.php?t=123", { }, optionAxios)
        .then(response => {
            if (response.data.status == 'success') {
                const data = response.data.json
                commit("saveSidebar", data)
            }
        })
    },
}

const mutations = {
    saveMenu(state, payload) {
      state.menu = payload
    },
    saveSidebar(state, payload) {
        state.sidebar = payload
    },
}


   
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}