import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createHead } from 'unhead'
import VueYandexMetrika from 'vue3-yandex-metrika'  

const head = createHead()
import "@fancyapps/ui/dist/fancybox/fancybox.css"
import "./plugins/fancyapps-ui.js"

/* Theme variables */
import './assets/css.css'


createApp(App).use(store).use(router).use(VueYandexMetrika, {
    id: 31654072,
    router: router,
	env: process.env.NODE_ENV,
    options: {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true,
        ecommerce:"dataLayer"
    }
}).mount('#app')
