
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import { useHead, useSeoMeta } from 'unhead'
import MainHeader from '@/components/MainHeader.vue'
import MainMenu from '@/components/MainMenu.vue'
import Sidebar from '@/components/Sidebar.vue'
import Prefooter from '@/components/Prefooter.vue'
import Footer from '@/components/Footer.vue'
import Postfooter from '@/components/Postfooter.vue'



export default defineComponent({
  name: 'HomeView',
  
  components: {
    MainHeader,
    MainMenu, 
    Sidebar, 
    Prefooter, 
    Footer, 
    Postfooter, 
  },
  data() {
      return {
        
      }
  },
  computed: {
    ...mapGetters("menu", {
      getMenu: "getMenu",
    }),
  },
  methods: {
    ...mapActions("menu", {
      updateMenu: "updateMenu",
    }),
  },
  mounted() {
    useSeoMeta({
      title: 'Завод нефтегазового оборудования Аврора-НЕФТЬ - Казахстан',
      description: 'Производство и поставки оборудования для нефтяной и газовой промышленности, оборудования для АЗС, резервуаров, устройств налива и пр. Каталог продукции. Онлайн-запрос цен. Опросные листы.',
      robots: "index, follow",
    })
    useHead({
      htmlAttrs: {
        lang: 'ru-KZ'
      },
      link: [
        { rel: 'canonical', href: 'https://aurora-oil.kz/' },
        { rel: 'alternate', href: 'https://aurora-oil.kz/', hreflang: 'ru-KZ' },
        { rel: 'alternate', href: 'https://aurora-oil.ru/', hreflang: 'ru' },
      ]
    })
  },
  setup () {
    //
  },
  watch: {
  }

})
