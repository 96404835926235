<template>
  <div>
    <!-- <router-link to="/">Home</router-link> -->
  </div>
  <router-view/>
</template>

<style>
#app {
  
}
</style>