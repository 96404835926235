import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/category/:id',
    component: () => import('../views/CategoryView.vue')
  },
  {
    path: '/sku/:id',
    component: () => import('../views/SkuView.vue')
  },
  {
    path: '/catalog/',
    name: 'maincatalog',
    component: () => import('../views/CatalogView.vue')
  },
  {
    path: '/about/',
    name: 'page1',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/about/delivery',
    name: 'page2',
    component: () => import('../views/DeliveryView.vue')
  },
  {
    path: '/about/patents',
    name: 'page3',
    component: () => import('../views/CertView.vue')
  },
  {
    path: '/about/references',
    name: 'page4',
    component: () => import('../views/ReferenceView.vue')
  },
  {
    path: '/about/gallery',
    name: 'page5',
    component: () => import('../views/GalleryView.vue')
  },
  {
    path: '/about/articles',
    name: 'page6a',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/about/contacts',
    name: 'page6',
    component: () => import('../views/ContactsView.vue')
  },
  {
    path: '/price',
    name: 'page7',
    component: () => import('../views/PriceView.vue')
  },
  {
    path: '/proektant',
    name: 'page8',
    component: () => import('../views/ProektantView.vue')
  },
  {
    path: '/oprosnii-list',
    name: 'page9',
    component: () => import('../views/ListSend.vue')
  },
  {
    path: '/oprosnye-listy',
    name: 'page10',
    component: () => import('../views/ListGeneral.vue')
  },
  {
    path: '/video-veernoe-soplo',
    name: 'page11',
    component: () => import('../views/SoploVideoView.vue')
  },
  {
    path: '/privacy',
    name: 'page12',
    component: () => import('../views/PrivacyView.vue')
  },
  {
    path: '/all-nomenklatura',
    name: 'page13',
    component: () => import('../views/NomenklaturaView.vue')
  },
  {
    path: '/all-nomenklatura/online',
    name: 'page14',
    component: () => import('../views/NomenklaturaOnlineView.vue')
  },
  {
    path: '/oprosnye-listy//oprosniki-doc',
    name: 'page15',
    component: () => import('../views/ListDoc.vue')
  },
  {
    path: '/oprosnye-listy//oprosniki-pdf',
    name: 'page16',
    component: () => import('../views/ListPdf.vue')
  },
  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    if (savedPosition) { 
      return savedPosition; 
    } else if (to.hash) {
      return { selector: to.hash }; 
    } else {
      return { top: 0 }
    }
  },
})

export default router
