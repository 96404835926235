
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'

export default defineComponent({
  name: 'MainMenu',
  components: {
    // 
  },
  data() {
      return {
        
      }
  },
  props: {
    msg: String
  },
  computed: {
    ...mapGetters("menu", {
      getSidebar: "getSidebar",
    }),
  },
  methods: {
    ...mapActions("menu", {
      updateSidebar: "updateSidebar",
    }),
    openSchemeModal() {
      const el = document.body
      el.classList.add('openScheme')
    }
  },
  mounted() {
    if (Object.keys(this.getSidebar).length < 1) {
      this.updateSidebar()
    }
  },
})
