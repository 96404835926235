
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'

export default defineComponent({
  name: 'MainHeader',
  components: {
    // 
  },
  data() {
      return {
        
      }
  },
  props: {
    msg: String
  },
  mounted() {
    const test = function() {
        let d = document
        let w = window
        let u = 'https://upload-2267ca95e3a65a46036ee44010c42ad2.storage.yandexcloud.net/crm/form/loader_14_072kma.js'
        var s=d.createElement('script')
        s.async=true;s.src=u+'?'+(Date.now()/180000|0)
        var h=d.getElementsByTagName('script')[0]
        //console.log(h)
        if (h.parentNode) {
          h.parentNode.insertBefore(s,h)
        }
        return true
    }
    test()
  },
  computed: {
    ...mapGetters("menu", {
      getMenu: "getMenu"
    }),
    
    
  },
  methods: {
    doCallBack() {
      alert(1)
    }
  },

})
