<template>
  <div id="postfooter">
    <div class="left">
      <small><router-link to="/privacy">Политика безопасности и обработки персональных данных</router-link></small>
    </div>
    <div class="right aurora">
      <a href="https://www.aurorainternet.ru/" target="_blank" title="ИТ-решения для промышленности"><img class="newlogoint" src="/aurorainternet.svg" alt="Аврора Интернет"></a>
    </div>
    <div class="clear"></div>
    <div id="cllbckmdl">
      <div id="cllbckwrpr">

      </div>
    </div>
    <div id="myScheme" @click="doStuff">
      <div id="rezervuarscheme" >
        <a href="https://aurora-oil.ru/sku/rezervuarnoe-oborudovanie/lyuki/lyuk-svetovoj-ls/" class="reschlink1 tooltip1" title="" target="_blank"></a>
        <a href="https://aurora-oil.ru/sku/protivopozharnoe-oborudovanie/ognepregraditeli/ognepregraditeli-op/" class="reschlink2 tooltip1" title="" target="_blank"></a>
        <a href="https://aurora-oil.ru/sku/rezervuarnoe-oborudovanie/patrubki-ventilyatsionnye/patrubok-ventilyatsionnyj-pv/" class="reschlink3 tooltip1" title="" target="_blank"></a>
        <a href="https://aurora-oil.ru/sku/rezervuarnoe-oborudovanie/lyuki/lyuk-zamernyj-lz/" class="reschlink4 tooltip1" title="" target="_blank"></a>
        <a href="https://aurora-oil.ru/sku/rezervuarnoe-oborudovanie/klapany-dyhatelnye/klapan-dyhatelnyj-sovmeshhennyj-kds-1500/" class="reschlink5 tooltip1" title="" target="_blank"></a>
        <a href="https://aurora-oil.ru/sku/oborudovanie-dlya-azs/klapany-dyhatelnye-oborudovanie-dlya-azs/klapan-dyhatelnyj-kdm-50/" class="reschlink6 tooltip1" title="" target="_blank"></a>
        <a href="https://aurora-oil.ru/sku/rezervuarnoe-oborudovanie/klapany-dyhatelnye/klapan-predohranitelnyj-gidravlicheskij-kpg/" class="reschlink7 tooltip1" title="" target="_blank"></a>
        <a href="https://aurora-oil.ru/sku/rezervuarnoe-oborudovanie/lyuki/lyuk-laz-ll/" class="reschlink8 tooltip1" title="" target="_blank"></a>
        <a href="https://aurora-oil.ru/sku/rezervuarnoe-oborudovanie/probootborniki/probootbornik-pspr/" class="reschlink9 tooltip1" title="" target="_blank"></a>
        <a href="https://aurora-oil.ru/sku/rezervuarnoe-oborudovanie/veernye-sopla-ustrojstva-razmyva-otlozhenij/soplo-veernoe-sv-1200/" class="reschlink10 tooltip1" title="" target="_blank"></a>
        <a href="https://aurora-oil.ru/sku/rezervuarnoe-oborudovanie/vzliv-i-sliv-produkta/kran-sifonnyj-ks/" class="reschlink11 tooltip1" title="" target="_blank"></a>
        <a href="https://aurora-oil.ru/sku/rezervuarnoe-oborudovanie/patrubki/patr_ppr/" class="reschlink12 tooltip1" title="" target="_blank"></a>
        <a href="https://aurora-oil.ru/sku/rezervuarnoe-oborudovanie/vzliv-i-sliv-produkta/truba-podemnaya/" class="reschlink13 tooltip1" title="" target="_blank"></a>
        <a href="https://aurora-oil.ru/sku/rezervuarnoe-oborudovanie/vzliv-i-sliv-produkta/priyomo-razdatochnye-ustrojstva-pru/" class="reschlink14 tooltip1" title="" target="_blank"></a>
        <a href="https://aurora-oil.ru/sku/protivopozharnoe-oborudovanie/generatory-peny-statsionarnye/generator-peny-srednej-kratnosti-statsionarnyj-gpss/" class="reschlink15 tooltip1" title="" target="_blank"></a>
        <a href="https://aurora-oil.ru/sku/rezervuarnoe-oborudovanie/klapany-dyhatelnye/klapan-dyhatelnyj-sovmeshhennyj-kds-3000/" class="reschlink16 tooltip1" title="" target="_blank"></a>
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    //msg: String
  },
  methods: {
    doStuff(event) {
      if (event.target.id == 'myScheme') {
        const el = document.body
        el.classList.remove('openScheme')
      }
    },
    doFormCallback() {
      //
    },
  },
  mounted () {
      setTimeout(this.doFormCallback, 500)
    }
})
export default class Postfooter extends Vue {
  //msg!: string
}
</script>

<style scoped>
.newlogoint {height:32px;margin-bottom: 10px;opacity: 0.7;} .newlogoint:hover {opacity:1;}
#myScheme {display:none;position:fixed;left:0;right:0;top:0;bottom:0;z-index:100;background:#000d;color:#fff;}
body.openScheme #myScheme {display:flex;align-items:center;justify-content:center;}
#rezervuarscheme {}
</style>
